import { useCallback, useContext } from 'react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import {  useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';

import { Base } from 'Base';
import { Tab } from 'components/Tab';

import { NFTsContext } from 'contexts/NFTs';

// import { Toxify } from 'views/the-bull-club/Toxify';
import { Rupture } from 'views/the-bull-club/Rupture';
// import { BullSwap } from 'views/the-bull-club/BullSwap';
import { Messages } from 'views/the-bull-club/Messages';
// import { Airdrops } from 'views/the-bull-club/Airdrops';
// import { DaoAssets } from 'views/the-bull-club/DaoAssets';
// import { DaoVoting } from 'views/the-bull-club/DaoVoting';
import { Statistics } from 'views/the-bull-club/Statistics';

import './index.scss';

// NOTE: At launch, we’ll only have the Rupture section working
const tabItems = [
	{
		title: 'Rupture',
		content: <Rupture />,
	},
	// {
	// 	title: 'Dao Assets',
	// 	// content:  <DaoAssets />,
	// 	content: null,
	// },
	{
		title: 'Toxify',
		// content:  <Toxify />,
		content: null,
	},
	{
		title: 'COMING SOON',
		// content:  <BullSwap />,
		content: null,
	},
	// {
	// 	title: '????????',
	// 	// content:  <DaoVoting />,
	// 	content: null,
	// },
	// {
	// 	title: '????????',
	// 	// content:  <Airdrops />,
	// 	content: null,
	// },
];

const TheBullClub = () => {
	const { setVisible } = useWalletModal();
	const { hasLunabull } = useContext(NFTsContext);
	const { wallet: solanaWallet, disconnect, connected: isConnected, connecting } = useSolanaWallet();

	const handleConnectButtonClick = useCallback(() => setVisible(true), [setVisible]);
	const handleDisconnectButtonClick = useCallback(() => disconnect().catch(() => {}), [disconnect]);

	return (
		<Base title="Club" isHeaderBlur>
			<div className="the-bull-club">
				<div className="poster" />
				<Statistics
					connecting={connecting}
					wallet={solanaWallet}
					isConnected={isConnected}
					onDisconnectButtonClick={handleDisconnectButtonClick}
					onConnectButtonClick={handleConnectButtonClick}
				/>
				{isConnected && hasLunabull ? (
					<Tab items={tabItems} defaultActiveKey="Rupture" />
				) : (
					<Messages connecting={connecting} isConnected={isConnected} onConnectButtonClick={handleConnectButtonClick} />
				)}
			</div>
		</Base>
	);
};

export default TheBullClub;
