import { Base } from 'Base';
import { Roadmap } from 'views/home/Roadmap';
import { Slider } from 'views/home/Slider';
import { BullClub } from 'views/home/BullClub';
import { CoreTeam } from 'views/home/CoreTeam';
import { AngelProtocol } from 'views/home/AngelProtocol';
import { SecondaryMarketplaces } from 'views/home/SecondaryMarketplaces';
import { ProtocolLogos } from 'views/home/ProtocolLogos';
import { ScrollableBulls } from 'views/home/ScrollableBulls';

const Home = () => (
	<Base title="Homepage">
		<Slider />
		<div className="blur-background">
			<AngelProtocol />
			<hr style={{ margin: '0 auto' }} />
			<SecondaryMarketplaces />
			<hr />
			<BullClub />
			<hr />
		</div>
		<ScrollableBulls />
		<hr />
		<Roadmap />
		{/* <hr /> */}
		{/* <CommunityTools /> */}
		<hr />
		<CoreTeam />
		<ProtocolLogos />
	</Base>
);

export default Home;
