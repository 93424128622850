import { SectionHeading } from 'components/SectionHeading';
import { SectionDescription } from 'components/SectionDescription';

import './index.scss';

export const Aside = ({ title, description, children }) => (
	<aside className="aside">
		<SectionHeading>{title}</SectionHeading>
		{description && <SectionDescription>{description}</SectionDescription>}
		{children}
	</aside>
);
