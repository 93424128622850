// import truncate from 'lodash/truncate';
// import useAsyncEffect from 'use-async-effect';
// import { Coins, MsgExecuteContract } from '@terra-money/terra.js';
// import { useWalletModal } from '@solana/wallet-adapter-react-ui';
// import { useCallback, useEffect, useMemo, useState } from 'react';
// import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
// import { useConnectedWallet as useConnectedTerraWallet, useWallet as useTerraWallet } from '@terra-money/wallet-provider';

import { Base } from 'Base';
// import { Button } from 'components/Button';
// import { Tooltip } from 'components/Tooltip';
import { SectionHeading } from 'components/SectionHeading';

// import { getGasPrices } from 'api';
// import { fetchPortalStats } from 'api/stats';
import portal from 'assets/img/portal.gif';

// import { lcdClient } from '../../api/constants';

import './index.scss';

const TheBullPortal = () => {
	// const terraWallet = useTerraWallet();
	// const { setVisible } = useWalletModal();
	// const [isLoading, setIsLoading] = useState(false);
	// const connectedTerraWallet = useConnectedTerraWallet();
	// const [portalStats, setPortalStats] = useState({ bulls: 0, cubes: 0 });
	// const [isWalletsConnected, setIsWalletsConnected] = useState(true);
	// const [portAssetsResponseMessage, setPortAssetsResponseMessage] = useState({ isSuccess: false, message: '' });
	// const { wallet: solanaWallet, publicKey, connect, connecting, connected: isConnected, disconnect, disconnecting } = useSolanaWallet();

	// const terraPublicKey = connectedTerraWallet?.walletAddress;
	// const solanaPublicKey = publicKey?.toString();

	// const terraWalletAddress = useMemo(
	// 	() => truncate(terraPublicKey, { length: 9, omission: '…' }) + terraPublicKey?.substring(terraPublicKey.length - 8, terraPublicKey.length),
	// 	[terraPublicKey]
	// );
	// const solanaWalletAddress = useMemo(
	// 	() => truncate(solanaPublicKey, { length: 9, omission: '…' }) + solanaPublicKey?.substring(solanaPublicKey.length - 8, solanaPublicKey.length),
	// 	[solanaPublicKey]
	// );

	// const hasTerraWalletAddress = terraWalletAddress !== 'undefined';
	// const hasSolanaWalletAddress = solanaWalletAddress !== 'undefined';

	// useEffect(() => {
	// 	if (hasTerraWalletAddress && hasSolanaWalletAddress) {
	// 		setIsWalletsConnected(true);
	// 	} else {
	// 		setIsWalletsConnected(false);
	// 	}
	// }, [hasTerraWalletAddress, hasSolanaWalletAddress]);

	// useEffect(() => {
	// 	if (solanaWallet && solanaWallet.readyState === 'Installed') {
	// 		connect();
	// 	}
	// }, [solanaWallet, connect]);

	// useAsyncEffect(async () => {
	// 	if (terraPublicKey) {
	// 		const portalStats = await fetchPortalStats(terraPublicKey);
	// 		setPortalStats(portalStats);
	// 		// console.info({ portalStats });
	// 	}
	// }, [terraPublicKey]);

	// const handleSelectWalletButtonClick = useCallback(() => {
	// 	setVisible(true);
	// }, [setVisible]);

	// const handleConnectTerraButtonClick = useCallback(() => {
	// 	terraWallet.connect();
	// }, [terraWallet]);

	// const handleDisconnectTerraButtonClick = useCallback(() => {
	// 	terraWallet.disconnect();
	// }, [terraWallet]);

	// const handleDisconnectSolanaButtonClick = useCallback(() => {
	// 	disconnect();
	// }, [disconnect]);

	// const handlePortAssetsButtonClick = useCallback(async () => {
	// 	try {
	// 		if (!connectedTerraWallet) {
	// 			return null;
	// 		}

	// 		setIsLoading(true);

	// 		const gasPrices = await getGasPrices();

	// 		const msgs = [
	// 			new MsgExecuteContract(connectedTerraWallet.walletAddress, 'terra152ea3pl2cs8q2gng7f257q49t3sgk6qzauqqsa', {
	// 				submit_solana_address: { solana_address: solanaPublicKey },
	// 			}),
	// 		];

	// 		const udenom = 'uluna';
	// 		const gasPrice = gasPrices[udenom];

	// 		const gas = '2000000';
	// 		const fees = { gas: gas, gasAdjustment: 1.5 };

	// 		if (udenom === 'uluna') delete fees.gas;

	// 		const account = await lcdClient.auth.accountInfo(connectedTerraWallet.walletAddress);
	// 		const sequenceNumber = account.getSequenceNumber();

	// 		const feeOptions = {
	// 			msgs,
	// 			gasPrices: new Coins(`${gasPrice}${udenom}`),
	// 			feeDenoms: [udenom],
	// 			...fees,
	// 		};

	// 		const fee = await lcdClient.tx.estimateFee([{ sequenceNumber: sequenceNumber }], feeOptions);

	// 		const transactionMsg = {
	// 			fee,
	// 			msgs,
	// 			isClassic: true,
	// 		};

	// 		// console.info({ gasPrices, gasPrice, feeOptions, fee, connectedTerraWallet, transactionMsg });

	// 		const response = await connectedTerraWallet.post(transactionMsg);

	// 		// console.info({ response });

	// 		if (response.success) {
	// 			setPortAssetsResponseMessage({
	// 				isSuccess: true,
	// 				message:
	// 					'YOUR BULL CLUB ASSETS HAVE SUCCESSFULLY ENTERED THE PORTAL. THE JOURNEY TO REACH THE SOLANA NETWORK IS QUITE LONG SO PLEASE SIT BACK AND BE PATIENT.',
	// 			});
	// 		}
	// 	} catch (error) {
	// 		setPortAssetsResponseMessage({ isSuccess: false, message: 'THERE WAS A PROBLEM PORTING YOUR BULL CLUB ASSETS TO SOLANA, PLEASE TRY AGAIN.' });
	// 		console.error('🚀 ~ file: index.js ~ line 126 ~ handlePortAssetsButtonClick ~ error', error);
	// 	} finally {
	// 		setIsLoading(false);
	// 	}
	// }, [connectedTerraWallet, solanaPublicKey]);

	return (
		<Base title="The Bull Portal">
			<div className="the-bull-portal">
				<div className="the-bull-portal-background" />
				<SectionHeading>The Bull Portal</SectionHeading>
				{/* <div className="connection-buttons">
					<Button onClick={hasTerraWalletAddress ? handleDisconnectTerraButtonClick : handleConnectTerraButtonClick}>
						{hasTerraWalletAddress ? terraWalletAddress : 'Connect Terra Wallet'}
					</Button>
					{isConnected ? (
						<Button onClick={handleDisconnectSolanaButtonClick}>
							{disconnecting ? 'Disconnecting Solana Wallet...' : solanaWalletAddress ?? 'Disconnect Solana Wallet'}
						</Button>
					) : (
						<Button onClick={handleSelectWalletButtonClick}>{connecting ? 'Connecting Solana Wallet...' : 'Connect Solana Wallet'}</Button>
					)}
				</div> */}
				<div className="port-stats">
					{/* <div className="port-stats-items">
						<div className="port-stats-item bulls">Bulls: {portalStats.bulls}</div>
						<div className="port-stats-item tesseracts">Tesseracts: {portalStats.cubes}</div>
					</div> */}
					<img className="portal" src={portal} width={340} height={340} alt="The Bull Club Logo" style={{ objectFit: 'cover' }} />
				</div>
				{/* <div className="action-button-wrapper">
					{portAssetsResponseMessage.message ? null : isWalletsConnected ? (
						connectedTerraWallet?.network.name === 'classic' ? (
							<Button onClick={handlePortAssetsButtonClick} disabled={isLoading}>
								{isLoading ? 'Porting...' : 'Port All Bulls And Tesseracts'}
							</Button>
						) : (
							<Tooltip content="Wrong network: Please connect your Terra extension to Terra Classic and refresh the page">
								<div>
									<Button disabled>Port All Bulls And Tesseracts</Button>
								</div>
							</Tooltip>
						)
					) : (
						<Button disabled>Connect Wallets To Port Assets</Button>
					)}
					{portAssetsResponseMessage.message && !portAssetsResponseMessage.isSuccess && <Button onClick={handlePortAssetsButtonClick}>Try Again</Button>}
				</div> 
				{portAssetsResponseMessage.message && <h2>{portAssetsResponseMessage.message}</h2>}*/}
				<h2 className="end-message">THE MIGRATION CLOSED ON APRIL 30th 2023. <br/>SEE YOU ON THE OTHER SIDE!</h2>
			</div>
		</Base>
	);
};

export default TheBullPortal;
