// import useAsyncEffect from 'use-async-effect';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useCallback, useContext, useMemo, useState } from 'react';

import { Loader } from 'components/Loader';
import { Button } from 'components/Button';
import { Pagination } from 'components/Pagination';
import { StatisticsCard } from 'views/the-bull-club/Statistics/StatisticsCard';

import { NFTsContext } from 'contexts/NFTs';
// import { fetchBullClubStats } from 'api/stats';

import blackBull from 'assets/img/bulls/black-bull.jpg';

import './index.scss';

export const Statistics = ({ connecting, isConnected, onDisconnectButtonClick, onConnectButtonClick }) => {
	const [order, setOrder] = useState(0);
	const [activeIndex, setActiveIndex] = useState(0);
	const [stats, /*setStats*/] = useState({
		bulls_2d: '2320',
		bulls_3d: '5103',
		cubes_used: '?',
		members: '?',
	});
	const { nfts, hasLunabull, isLoading } = useContext(NFTsContext);

	const handleActiveIndexChange = useCallback(({ activeIndex }) => setActiveIndex(activeIndex), [setActiveIndex]);

	// useAsyncEffect(async () => {
	// 	const stats = await fetchBullClubStats();
	// 	setStats(stats);
	// }, []);

	const statisticsCards = useMemo(
		() => [
			{ title: 'Bull Club Members', value: stats.members, order: 1 },
			{ title: 'Tesseracts Left', value: stats.cubes_used, order: 2 },
			{ title: 'Bulls Migrated', value: stats.bulls_3d, order: 4 },
			{ title: 'Tesseracts Migrated', value: stats.bulls_2d, order: 5 },
		],
		[stats]
	);

	const handlePrevButtonClick = useCallback(() => {
		if (order >= 1) {
			setOrder(order => order - 1);
		}
	}, [order]);

	const handleNextButtonClick = useCallback(() => {
		if (order < nfts.length - 1) {
			setOrder(order => order + 1);
		}
	}, [nfts, order]);

	return (
		<div className="statistics">
			<span className="statistics-label">Statistics</span>
			<div className="statistics-cards">
				<div className="statistics-card main-card" data-order="3">
					<div className="image-wrapper">
						{isLoading && <Loader />}
						<img src={isConnected && hasLunabull && !isLoading ? nfts[order]?.image : blackBull} alt={nfts[order]?.name} />
					</div>
					{isConnected && hasLunabull && (
						<div className="main-card-header">
							<span>Select your bull</span>
							<em>#{nfts[order]?.name?.split('#')[1]}</em>
						</div>
					)}
					<div className="card-actions">
						{isConnected && hasLunabull ? (
							<>
								<Button variant="white" size="medium" onClick={handlePrevButtonClick} disabled={order === 0}>
									Prev
								</Button>
								{/* <Button variant="primary-outlined">Unstake</Button> */}
								<Button variant="white" size="medium" onClick={handleNextButtonClick} disabled={order >= nfts.length - 1}>
									Next
								</Button>
							</>
						) : (
							<Button
								//
								variant="primary-outlined"
								isFullWidth
								disabled={isLoading}
								onClick={isConnected ? onDisconnectButtonClick : onConnectButtonClick}
							>
								{isLoading ? 'Loading...' : isConnected ? 'Disconnect Wallet' : connecting ? 'Connecting Wallet...' : 'Connect Wallet'}
							</Button>
						)}
					</div>
					{/* <p>{isConnected ? 'Stake a Bull to participate in DAO governance and receive airdrops' : 'Connect your wallet to enter The Bull Club'}</p> */}
				</div>
				{statisticsCards.map((props, index) => (
					<StatisticsCard key={index} {...props} />
				))}
			</div>
			<div className="statistics-cards-slider">
				<div className="statistics-cards-slider-header">
					<span className="statistics-label">Statistics</span>
					<Pagination activeIndex={activeIndex} itemsCount={statisticsCards.length} />
				</div>
				<Swiper slidesPerView={1} spaceBetween={10} width={300} onActiveIndexChange={handleActiveIndexChange}>
					{statisticsCards.map((props, index) => (
						<SwiperSlide key={index}>
							<StatisticsCard {...props} />
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</div>
	);
};
