import { Link } from 'components/Link';

import MostBullishImage from 'assets/img/home/slider/poker.png';
import MostBullishImageMobile from 'assets/img/home/slider/poker-mobile.png';

export const MostBullish = () => (
	<div className="homepage-image-container-most-bullish">
		<img className="homepage-image-most-bullish" src={MostBullishImage} alt="Slider 3" />
		<img className="homepage-image-most-bullish-mobile" src={MostBullishImageMobile} alt="Slider 2" />
		<div className="svg-logo-container-most-bullish">
			<p className="most-bullish-font">THE MOST</p>
			<p className="most-bullish-font2">BULLISH</p>
			<p className="most-bullish-font">CLUB IN CRYPTO</p>
		</div>
		<Link to="/the-bull-club" className="most-bullish-button button">
			Enter the club
		</Link>
	</div>
);
