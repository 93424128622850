import classNames from 'classnames';

import './index.scss';

export const Pagination = ({ activeIndex, itemsCount }) => (
	<div className="pagination">
		<span className={classNames({ active: activeIndex === 0 })} />
		<span className={classNames({ active: activeIndex === 1 })} />
		<span className={classNames({ active: activeIndex === 2 })} />
		<span className={classNames({ active: activeIndex === 3 })} />
		{/* <span className={classNames({ active: activeIndex === 4 })} /> */}
	</div>
);
