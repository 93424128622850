import { StrokedText } from 'components/StrokedText';

import './index.scss';

export const StatisticsCard = ({ title, value, order }) => (
	<div className="statistics-card" data-order={order}>
		<StrokedText text={value} />
		<em>{title}</em>
	</div>
);
