import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-hot-toast';
import useAsyncEffect from 'use-async-effect';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAnchorWallet, useWallet } from '@solana/wallet-adapter-react';

import { Card } from 'components/Card';
import { Aside } from 'components/Aside';
import { Button } from 'components/Button';
import { Tooltip } from 'components/Tooltip';
// import { Tooltip } from 'components/Tooltip';
import { CardMiddle } from 'components/CardMiddle';
import { FilterTabButtons } from 'components/FilterTabButtons';
import { showRuptureModal } from 'views/the-bull-club/Rupture/RuptureModal';

// import { mockBullImages } from 'constants';
import { applyCube, getAccountInfo, getAllNftData, switchUri } from 'api/solana';

import { NFTsContext } from 'contexts/NFTs';

import tesseract from 'assets/img/tesseracts/filters/tesseract.webp';

import './index.scss';

const getAsideProps = activeTab => ({
	title: `${activeTab === 'bond' ? 'Upgrade' : 'Interchange'} Your Bull`,
	description:
		activeTab === 'bond' ? (
			<>
				Upgrade your Bull to 3D by bonding a tesseract to it. Once bonded the Tesseract is burned and your Bull becomes interdimensional.
				<br />
				<br />
				This means you can swap between the 2D and 3D versions of your Bull in the “Interchange” tab.
				<br />
				<br />
				<a class="tessLink" target='_blank' href='https://magiceden.io/marketplace/tesseract' rel='noreferrer'>BUY TESSERACTS</a>
			</>
		) : (
			<>
				Interchange your bulls between 2D &#60;&#62; 3D.
				<br />
				<br />
				Your bull must have has a tesseract bonded to it to be able to swap dimensions.
				<br />
				<br />
				<a class="tessLink" target='_blank' href='https://magiceden.io/marketplace/tesseract' rel='noreferrer'>BUY TESSERACTS</a>
			</>
		),
});

const getThirdCardImage = (bulls, order, activeTab) => {
	if (activeTab === 'bond') {
		return require(`assets/img/bulls/black-bull.webp`);
	}

	if (bulls.interchange[order]?.choice === 0) {
		return bulls.interchange[order]?.threeDImage;
	}

	if (bulls.interchange[order]?.choice === 1) {
		return bulls.interchange[order]?.twoDImage;
	}

	return null;
};

const getDisabledButtonTooltipText = (bulls, activeTab, cubesAmount) => {
	if (isEmpty(bulls[activeTab])) {
		return `There is no ${activeTab === 'bond' ? 'bondable' : 'interchangeable'}  bulls`;
	}
	if (cubesAmount <= 0) {
		return `You don't have tesseract to bond`;
	}

	return null;
};

export const Rupture = () => {
	const wallet = useAnchorWallet();
	const { publicKey: walletPublicKey } = useWallet();

	const { nfts, setNfts } = useContext(NFTsContext);
	const [isLoading, setIsLoading] = useState(false);

	const [cubesAmount, setCubesAmount] = useState(0);
	const [activeTab, setActiveTab] = useState('bond');
	const [bulls, setBulls] = useState({ bond: [], interchange: [] });
	const [order, setOrder] = useState({ bond: 0, interchange: 0 });

	const handleTabChange = useCallback(tabKey => setActiveTab(tabKey), [setActiveTab]);

	const handlePrevButtonClick = useCallback(() => {
		if (order[activeTab] >= 1) {
			setOrder(orders => ({ ...orders, [activeTab]: orders[activeTab] - 1 }));
		}
	}, [order, activeTab]);

	const handleNextButtonClick = useCallback(() => {
		if (order[activeTab] < bulls[activeTab].length - 1) {
			setOrder(orders => ({ ...orders, [activeTab]: orders[activeTab] + 1 }));
		}
	}, [activeTab, bulls, order]);

	const handleBondButtonClick = useCallback(async () => {
		try {
			if (!isEmpty(bulls.bond)) {
				setIsLoading(true);
				const bonded = await applyCube({ wallet, walletPublicKey, nftMint: bulls.bond[order.bond].nftMint, userPub: walletPublicKey });
				const interchanged = await switchUri({ wallet, nftMint: bulls.bond[order.bond].nftMint, userPub: walletPublicKey, choice: 1 });
				const updatedNfts = await getAllNftData({ wallet, isConnected: true });
				const accountInfo = await getAccountInfo(walletPublicKey);

				// console.info({ bonded });

				const newNft = updatedNfts.find(({ mint }) => mint === bulls.bond[order.bond].mint);
				const previousNft = bulls.bond[order.bond];

				// console.info({ newNft, previousNft });

				if (bonded && interchanged) {
					showRuptureModal({ activeTab: 'bond', previousNft, newNft });
					setNfts(updatedNfts);
					setCubesAmount(accountInfo?.amount ? Number(accountInfo.amount) : 0);

					toast.success('Successfully bonded and switched to 3D!', { style: { wordBreak: 'break-all' } });
				}
				setIsLoading(false);
			}
		} catch (error) {
			console.error('🚀 ~ file: index.js ~ line 70 ~ handleBondButtonClick ~ error', error);
		}
	}, [wallet, bulls, order, walletPublicKey, setNfts, setCubesAmount]);

	const handleInterchangeButtonClick = useCallback(async () => {
		try {
			if (!isEmpty(bulls.interchange)) {
				setIsLoading(true);

				const choice = bulls.interchange[order.interchange].choice === 0 ? 1 : bulls.interchange[order.interchange].choice === 1 ? 0 : null;
				const interchanged = await switchUri({ wallet, nftMint: bulls.interchange[order.interchange].nftMint, userPub: walletPublicKey, choice });

				const updatedNfts = await getAllNftData({ wallet, isConnected: true });
				const accountInfo = await getAccountInfo(walletPublicKey);

				const newNft = updatedNfts.find(({ mint }) => mint === bulls.interchange[order.interchange].mint);
				const previousNft = bulls.interchange[order.interchange];

				// console.info({ newNft, previousNft });

				if (interchanged) {
					showRuptureModal({ activeTab: 'interchange', previousNft, newNft });
					setNfts(updatedNfts);
					setCubesAmount(accountInfo?.amount ? Number(accountInfo.amount) : 0);

					toast.success('Successfully interchanged!', { style: { wordBreak: 'break-all' } });
				}

				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
			console.error('🚀 ~ file: index.js ~ line 93 ~ handleInterchangeButtonClick ~ error', error);
		}
	}, [bulls, order, setCubesAmount, setNfts, wallet, walletPublicKey]);

	useEffect(() => {
		setBulls({
			bond: nfts.filter(({ hasCube }) => !hasCube),
			interchange: nfts.filter(({ hasCube }) => hasCube),
		});
	}, [nfts]);

	useAsyncEffect(async () => {
		if (walletPublicKey) {
			const accountInfo = await getAccountInfo(walletPublicKey);
			// console.info('accountInfo | cubesAmount', { accountInfo });
			setCubesAmount(accountInfo?.amount ? Number(accountInfo.amount) : 0);
		}
	}, [walletPublicKey]);

	const selectedBull = bulls[activeTab][order[activeTab]];

	const mainCTAButton =
		activeTab === 'bond' ? (
			<Button
				variant="white"
				isLoading={isLoading}
				isFullWidth
				disabled={isEmpty(bulls[activeTab]) || isLoading || cubesAmount <= 0}
				onClick={handleBondButtonClick}
			>
				Bond
			</Button>
		) : (
			<Button
				variant="white"
				isLoading={isLoading}
				isFullWidth
				disabled={isEmpty(bulls[activeTab]) || isLoading}
				onClick={handleInterchangeButtonClick}
			>
				Interchange
			</Button>
		);

	return (
		<section className="rupture the-bull-club-aside-section-wrapper">
			<Aside {...getAsideProps(activeTab)}>
				<FilterTabButtons defaultActiveKey="bond" items={['bond', 'interchange']} onTabChange={handleTabChange} />
			</Aside>
			<main className="rupture-content main-content">
				<div className="the-bull-club-cards without-slider">
					<Card
						variant="primary"
						img={selectedBull?.image ?? require(`assets/img/bulls/black-bull.webp`)}
						actionsTitle={`${isEmpty(bulls[activeTab]) ? (activeTab === 'bond' ? 'All bulls have a tesseract' : 'No Bonded Bulls detected') : 'Select your bull'} `}
						actionsLabel={selectedBull?.name.split('#')[1]}
						actions={
							<>
								<Button
									//
									variant="white"
									isFullWidth
									onClick={handlePrevButtonClick}
									disabled={order[activeTab] === 0}
								>
									Prev
								</Button>
								<Button
									//
									variant="white"
									isFullWidth
									onClick={handleNextButtonClick}
									disabled={order[activeTab] >= bulls[activeTab].length - 1}
								>
									Next
								</Button>
							</>
						}
					/>
					<CardMiddle
						img={tesseract}
						title={cubesAmount}
						bottomText={activeTab === 'bond' ? `${cubesAmount} Tesseracts Left!` : 'Interchange your bull'}
					/>
					<Card
						variant="primary"
						img={getThirdCardImage(bulls, order[activeTab], activeTab) || require(`assets/img/bulls/black-bull.webp`)}
						actionsTitle={`${selectedBull?.choice === 0 ? '3D' : '2D'} Version`}
						actions={
							isEmpty(bulls[activeTab]) ? (
								<Tooltip content={getDisabledButtonTooltipText(bulls, activeTab, cubesAmount)}>
									<div style={{ width: '100%' }}>{mainCTAButton}</div>
								</Tooltip>
							) : (
								mainCTAButton
							)
						}
					/>
				</div>
			</main>
		</section>
	);
};
