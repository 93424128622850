import classNames from 'classnames';
import { useCallback, useState } from 'react';

import './index.scss';

export const FilterTabButtons = ({ defaultActiveKey, items, onTabChange }) => {
	const [activeTab, setActiveTab] = useState(defaultActiveKey);
	const handleTabButtonClick = useCallback(
		tabKey => {
			setActiveTab(tabKey);
			onTabChange(tabKey);
		},
		[onTabChange]
	);

	return (
		<div className="filter-tab-buttons">
			{items.map((key, index) => (
				<button key={key} className={classNames({ active: activeTab === key })} onClick={() => handleTabButtonClick(key)}>
					<span>{key}</span>
				</button>
			))}
		</div>
	);
};
