import classNames from 'classnames';

import './index.scss';

export const CardMiddle = ({ img, title, className, bottomText }) => (
	<div className={classNames('card', 'card-middle', className)}>
		{title && (
			<div className="card-middle-header">
				<em>{title}</em>
				<span className="label-icon" />
			</div>
		)}
		{img && <img src={img} alt="Pit Bulls Airdrops" />}
		{bottomText && <div className="bottom-text">{bottomText}</div>}
	</div>
);
