import MigrationMobileImage from 'assets/img/home/roadmap/7-mobile.png';

import './index.scss';

export const RoadmapCard = ({ index, type, date, title, description, poster }) => (
	<div className="roadmap-items-card" data-direction={index % 2 === 1 ? 'reverse' : 'normal'}>
		<div className="roadmap-items-card-content">
			<div className="roadmap-items-card-content-type-date">
				<em>{type}</em>
				<time>{date}</time>
			</div>
			<h2>{title}</h2>
			{description}
		</div>
		<div className="roadmap-items-card-image">
			{type === 'Migration' ? (
				<>
					<img src={poster} alt={title} className="migration-image" />
					<img src={MigrationMobileImage} alt={title} className="migration-image-mobile" />
				</>
			) : (
				<img src={poster} alt={title} />
			)}
		</div>
	</div>
);
