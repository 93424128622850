import classNames from 'classnames';

import { CardHeader } from 'components/CardHeader';

import './index.scss';

export const Card = ({ variant = 'secondary', img, title, label, actions, actionsTitle, actionsLabel, className }) => (
	<div className={classNames('card', className)} data-variant={variant}>
		{title && label && <CardHeader title={title} label={label} />}
		{img && <img src={img} alt="Pit Bulls Airdrops" />}
		{(actionsTitle || actionsLabel) && (
			<div className="card-actions-title">
				<span>{actionsTitle}</span>
				<em>{actionsLabel}</em>
			</div>
		)}
		{actions && <div className="card-actions">{actions}</div>}
	</div>
);
