import isEmpty from 'lodash/isEmpty';
import useAsyncEffect from 'use-async-effect';
import { createContext, useState } from 'react';
import { useAnchorWallet, useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';

import { getAllNftData } from 'api/solana';

export const NFTsContext = createContext(null);

export const NFTsContextProvider = ({ children }) => {
	const wallet = useAnchorWallet();
	const { connected: isConnected } = useSolanaWallet();

	const [nfts, setNfts] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [hasLunabull, setHasLunabull] = useState(false);
	const [shouldRefresh, setShouldRefresh] = useState(false);

	useAsyncEffect(async () => {
		if (isConnected) {
			setIsLoading(true);

			const nfts = await getAllNftData({ wallet, isConnected });
			// console.info({ nfts });

			if (!isEmpty(nfts)) {
				setNfts(nfts);
				setHasLunabull(true);
			}
			setIsLoading(false);
		}
	}, [isConnected, shouldRefresh]);

	return <NFTsContext.Provider value={{ nfts, setNfts, hasLunabull, isLoading, setShouldRefresh }}>{children}</NFTsContext.Provider>;
};
