import './index.scss';

export const MarketplaceCard = ({ title, logo, url }) => (
	<li className="marketplace-card">
		<a href={url} target="_blank" rel="noreferrer">
			<img src={logo} alt={title} />
			<span style={{ userSelect: 'none' }}>{title}</span>
		</a>
	</li>
);
