import truncate from 'lodash/truncate';
import Select from 'react-select';
import classNames from 'classnames';
import makeAnimated from 'react-select/animated';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';

import { partners } from 'constants';

import { Link } from 'components/Link';
import { Logo } from 'components/Logo';
import { Button } from 'components/Button';

import './index.scss';

const navItems = [
	{ title: 'Home', link: '/' },
	// { title: 'Collection', link: '/collection' },
	// { title: 'Activity', link: '/activity' },
	// { title: 'Migration', link: '/the-bull-portal' },
	{ title: 'The Club', link: '/the-bull-club' },
];

const marketplaceOptions = [
	{ label: 'TENSOR', value: 'tensor' },
	{ label: 'MAGIC EDEN', value: 'magicEden' },
	// { label: 'SOLANART', value: 'solanart' },
	{ label: 'OPENSEA', value: 'opensea' },
];

const getCustomSelectStyles = ({ menuIsOpen }) => ({
	control: (base, state) => ({
		...base,
		marginRight: '10px',
		borderRadius: '5px',
		maxHeight: '40px',
		backgroundColor: 'transparent',
		border: state.isFocused ? '1px solid #ff006c' : '1px solid #ff006c',
		boxShadow: 'none',
		fontFamily: 'OCR Extended, sans-serif',
		fontSize: '14px',
		padding: '8px 18px',
		cursor: 'pointer',
		display: 'flex',
		overflow: 'hidden',
		position: 'relative',
		zIndex: 4,
		color: 'white',
		lineHeight: 1,
		textTransform: 'uppercase',
		transition: 'all 0.3s ease-in-out',
		alignItems: 'center',
		userSelect: 'none',
		'&:hover': {
			backgroundColor: '#ff006c',
			'&::before': { width: '116%', opacity: 1 },
			'&::after': { width: '120%', opacity: 1 },
		},
	}),
	valueContainer: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			color: 'white',
			padding: 0,
		};
	},
	placeholder: base => ({
		...base,
		color: 'white',
		marginRight: '10px',
	}),
	indicatorSeparator: () => ({ display: 'none' }),
	dropdownIndicator: (base, state) => ({
		...base,
		color: 'white',
		padding: '2px 2px 2px 0',
		transition: 'transform 0.3s ease-in-out',
		fontSize: '16px',
		transform: menuIsOpen ? 'rotate(180deg)' : null,
		'&:hover': {
			color: 'white',
		},
	}),
	option: styles => {
		return {
			...styles,
			position: 'relative',
			backgroundColor: '#df0b63',
			color: 'white',
			textAlign: 'center',
			fontFamily: 'OCR Extended, sans-serif',
			fontSize: '14px',
			padding: '15px',
			cursor: 'pointer',
			transition: 'all 0.3s ease-in-out',
			maxWidth: '100%',
			'&:last-child': {
				'&::after': {
					display: 'none',
				},
			},
			'&::after': {
				content: '""',
				height: '1px',
				background: '#f1f0f170',
				width: '90%',
				position: 'absolute',
				left: '10px',
				bottom: 0,
				right: 0,
			},
			'&:hover': {
				backgroundColor: '#c40454',
			},
		};
	},
	menuList: base => ({
		...base,
		paddingTop: 0,
		paddingBottom: 0,
		backgroundColor: '#ff006c',
		borderRadius: '5px',
	}),
});

export const Header = ({ isHeaderBlur = false }) => {
	const { setVisible } = useWalletModal();
	const animatedComponents = makeAnimated();
	const [marketplaceValue] = useState(null);
	const [menuIsOpen, setMenuOpen] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const { wallet, connect, connected: isConnected, disconnect, publicKey } = useSolanaWallet();

	const solanaPublicKey = publicKey?.toString();
	const solanaWalletAddress = useMemo(
		() => truncate(solanaPublicKey, { length: 8, omission: '…' }) + solanaPublicKey?.substring(solanaPublicKey.length - 8, solanaPublicKey.length),
		[solanaPublicKey]
	);

	const handleSelectWalletButtonClick = useCallback(() => setVisible(true), [setVisible]);
	const handleDisconnectButtonClick = useCallback(() => disconnect().catch(() => {}), [disconnect]);

	useEffect(() => {
		if (wallet && wallet.readyState === 'Installed') {
			connect();
		}
	}, [wallet, connect]);

	const handleBurgerButtonClick = useCallback(() => {
		setIsMenuOpen(isMenuOpen => !isMenuOpen);
	}, [setIsMenuOpen]);

	const customSelectStyles = useMemo(() => getCustomSelectStyles({ menuIsOpen }), [menuIsOpen]);

	const marketplaceFunc = useCallback(option => {
		if (option.value === 'magicEden') {
			window.open(partners[1].url);
		} else if (option.value === 'tensor') {
			window.open(partners[0].url);
		} else if (option.value === 'opensea') {
			window.open(partners[2].url);
		} else {
			return;
		}
	}, []);

	return (
		<header className={classNames('header', { blur: isHeaderBlur })}>
			<div className="container-wrapper extended">
				<div className="header-wrapper">
					<button className={classNames('burger', { active: isMenuOpen })} onClick={handleBurgerButtonClick} onMouseLeave={handleBurgerButtonClick}>
						<div>
							<span></span>
							<span></span>
						</div>
					</button>
					<nav className={classNames({ active: isMenuOpen })}>
						<button className="menu-close-button" onClick={handleBurgerButtonClick} />
						<ul>
							{navItems.map(({ title, link }, index) => (
								<li key={index}>
									<Link isNavLink to={link} title={title}>
										{title}
									</Link>
								</li>
							))}
						</ul>
						<Button className="connect-wallet-button" variant="primary">
							Connect Wallet
						</Button>
					</nav>
					<Logo />
					<div className="main-ctas">
						<Select
							styles={customSelectStyles}
							placeholder="Marketplace"
							components={animatedComponents}
							options={marketplaceOptions}
							onChange={marketplaceFunc}
							closeMenuOnSelect={false}
							value={marketplaceValue}
							onMenuOpen={() => setMenuOpen(true)}
							onMenuClose={() => setMenuOpen(false)}
							classNamePrefix="select"
						/>
						<Button variant="primary" onClick={isConnected ? handleDisconnectButtonClick : handleSelectWalletButtonClick}>
							{isConnected ? solanaWalletAddress : 'Connect Wallet'}
						</Button>
					</div>
					<button className="wallet" onClick={isConnected ? handleDisconnectButtonClick : handleSelectWalletButtonClick} />
				</div>
			</div>
		</header>
	);
};
