import { Link as RouterLink, NavLink } from 'react-router-dom';

import './index.scss';

export const Link = ({ href, to, children, isNavLink, size, variant, className, ...otherProps }) =>
	href ? (
		<a className={className ? className : 'link'} href={href} data-size={size} data-variant={variant} {...otherProps}>
			{children}
		</a>
	) : isNavLink ? (
		<NavLink className={className ? className : 'link router-nav-link'} to={to} end data-size={size} data-variant={variant} {...otherProps}>
			{children}
		</NavLink>
	) : (
		<RouterLink className={className ? className : 'link router-link'} to={to} data-size={size} data-variant={variant} {...otherProps}>
			{children}
		</RouterLink>
	);
