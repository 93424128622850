import { Base } from 'Base';
import { Link } from 'components/Link';
import MostBullishImage from 'assets/img/home/slider/poker.png';
import './index.scss';

const NotFound = () => {
	return (
		<Base title="NotFound">
			<div className="not-found-container">
				<img src={MostBullishImage} alt="image404" className="image-404" />
				<div className="not-found-inner-div">
					<p className="not-found-font">This page</p>

					<p className="not-found-font">doesn't exist</p>
					<Link to="/" variant="button">
						Go to homepage
					</Link>
				</div>
			</div>
		</Base>
	);
};

export default NotFound;
