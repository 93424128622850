import classNames from 'classnames';

import './index.scss';

export const CardHeader = ({ title, label, className }) => (
	<div className={classNames('card-header', className)}>
		<em>{title}</em>
		<span>{label}</span>
	</div>
);
