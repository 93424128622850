import omit from 'lodash/omit';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Connection, PublicKey } from '@solana/web3.js';
import { utils, Program, AnchorProvider } from '@project-serum/anchor';
import { getAssociatedTokenAddress, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { createConnectionConfig, getParsedNftAccountsByOwner, isValidSolanaAddress as getIsValidSolanaAddress } from '@nfteyez/sol-rayz';

import { commitment, preflightCommitment, METAPLEX_PROGRAM_ID, idlPool, endpoint, TOKEN_METADATA_PROGRAM_ID, SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID } from './constants';

export const solanaEnvSig = wallet => {
	const connection = new Connection(endpoint);

	const skipPreflight = true;
	const provider = new AnchorProvider(connection, wallet, {
		preflightCommitment,
		commitment,
		skipPreflight,
	});

	const programId = new PublicKey('5GxHfTey68BkbQi7gTLahT5rdX6BWiHarRAdy8mwyKmk');
	const program = new Program(idlPool, programId, provider);

	return { program };
};

export const getAllNftData = async ({ wallet, isConnected }) => {
	try {
		if (isConnected) {
			let provider;

			if ('solana' in window) provider = window.solana;
			if ('solflare' in window) provider = window.solflare;
			if ('backpack' in window) provider = window.backpack;
			
			if (provider) {
				const { program } = solanaEnvSig(wallet);
				const publicAddress = wallet.publicKey;
				const connect = createConnectionConfig(endpoint);

				const isValidSolanaAddress = getIsValidSolanaAddress(publicAddress);
				if (isValidSolanaAddress) {
					const parsedNfts = await getParsedNftAccountsByOwner({
						publicAddress,
						connection: connect,
						serialization: true,
					});

					const bullPromises = parsedNfts.map(async (parsedNft, index) => {
						try {
							const nftMint = new PublicKey(parsedNft.mint);
							const bull = await findBullPda(program.programId, nftMint);
							const onChainData = await program.account.bull.fetch(bull.pda);
							const response = await axios.get(parsedNft.data.uri);
							const twoDResponse = await axios.get(onChainData.uriTwoD);
							const threeDResponse = await axios.get(onChainData.uriThreeD);
							// console.info({ index, onChainData });

							const nft = {
								...omit(parsedNft, ['data']),
								...parsedNft.data,
								...threeDResponse.data,
								...response.data,
								threeDImage: threeDResponse.data.image,
								twoDImage: twoDResponse.data.image,
								nftMint,
								...onChainData,
							};

							return onChainData.bump === bull.bump ? nft : undefined;
						} catch (error) {
							return undefined;
						}
					});

					const bulls = await Promise.all(bullPromises);

					return bulls.filter(Boolean);
				}

				return;
			}
		}
	} catch (error) {
		console.error('🚀 ~ file: solana.js ~ line 180 ~ getAllNftData ~ error', error);
		throw error;
	}
};

export const getAccountInfo = async (publicKey) => {
	try {
		let tessInfo = await getTesseractKey(publicKey);
		return { amount: tessInfo.amount };
	} catch (error) {
		console.error('🚀 ~ file: solana.js ~ line 36 ~ getAccountInfo ~ error', error);
	}
};

export const findMetaPda = async nftMint => {
	const metaProgramId = new PublicKey(METAPLEX_PROGRAM_ID);
	const [metaPda, metaBump] = PublicKey.findProgramAddressSync(
		[utils.bytes.utf8.encode('metadata'), metaProgramId.toBuffer(), nftMint.toBuffer()],
		metaProgramId
	);
	return { pda: metaPda, bump: metaBump };
};

export const getMetadataAccount = async (
	mint
  ) => {
	return (
	  await PublicKey.findProgramAddress(
		[
		  Buffer.from("metadata"),
		  new PublicKey(TOKEN_METADATA_PROGRAM_ID).toBuffer(),
		  mint.toBuffer(),
		],
		new PublicKey(TOKEN_METADATA_PROGRAM_ID)
	  )
	)[0];
};

export const findBossPda = async programId => {
	const [bossPda, bossBump] = PublicKey.findProgramAddressSync([utils.bytes.utf8.encode('aboss')], programId);
	return { pda: bossPda, bump: bossBump };
};

export const findBullPda = async (programId, nftMint) => {
	const [bullPda, bullBump] = PublicKey.findProgramAddressSync([nftMint.toBuffer()], programId);
	return { pda: bullPda, bump: bullBump };
};

export const getTokenAccount = async function (
	wallet,
	mint,
  ) {
	return (
		await PublicKey.findProgramAddress(
			[wallet.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
			new PublicKey(SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID)
		)
	)[0];
};

export const applyCube = async ({ wallet, walletPublicKey, nftMint, userPub }) => {
	const { program } = solanaEnvSig(wallet);

	// console.info({ wallet, userPub, nftMint }, 'applyCube');
	let tessInfo = await getTesseractKey(walletPublicKey);
	const hardcodedCubeMint = new PublicKey(tessInfo.randKey);
	// const cubeMintPubKey = new PublicKey(cubeMint);
	const metaPda = await getMetadataAccount(hardcodedCubeMint);

	try {
		const boss = await findBossPda(program.programId);
		const bull = await findBullPda(program.programId, nftMint);
		const userCubeAta = await getAssociatedTokenAddress(hardcodedCubeMint, userPub);

		const result = await program.methods
			.applyCube()
			.accounts({
				bull: bull.pda,
				boss: boss.pda,
				userTesseract: userCubeAta,
				tesseractMint: hardcodedCubeMint,
				tessMetadata: metaPda,
				nftMint,
				user: userPub,
				nftProgramId: new PublicKey(TOKEN_METADATA_PROGRAM_ID),
			})
			.rpc();

		return result;
	} catch (error) {
		console.error('🚀 ~ file: solana.js ~ line 62 ~ applyCube ~ error', error);

		// Error Code: AlreadyHasCube. Error Number: 6004. Error Message: The bull already has a tesseract!
		if (error.message.includes('6004')) {
			toast.error(`Error during bonding: The bull already has a tesseract!`);
		} else {
			toast.error(`Error during bonding: ${error.message}`);
		}

		throw error;
	}
};

export const getTesseractKey = async (walletPublicKey) => {
	let provider;
	let randomTess;
	let totalTess;

	if ('solana' in window) provider = window.solana;
	if ('solflare' in window) provider = window.solflare;
	if ('backpack' in window) provider = window.backpack;

	if (provider) {
		const publicAddress = walletPublicKey;
		const connect = createConnectionConfig(endpoint);

		const isValidSolanaAddress = getIsValidSolanaAddress(publicAddress);

		if (isValidSolanaAddress) {
			const parsedNfts = await getParsedNftAccountsByOwner({
				publicAddress,
				connection: connect,
				serialization: true,
			});
			randomTess = parsedNfts.find(item => item.updateAuthority === 'BuLLi9RAxjBvDdrLVYsgs7Nbywvn7KM8Bni2VwHu2iRW' && item.data.symbol === 'TESS')
			totalTess = parsedNfts.filter(item => item.updateAuthority === 'BuLLi9RAxjBvDdrLVYsgs7Nbywvn7KM8Bni2VwHu2iRW' && item.data.symbol === 'TESS').length;

		}
	}

	return { randKey: randomTess ? randomTess.mint : '', amount: totalTess }
}

export const switchUri = async ({ wallet, nftMint, userPub, choice }) => {
	const { program } = solanaEnvSig(wallet);

	// console.info({ wallet, userPub, nftMint, choice }, 'switchUri');

	try {
		const boss = await findBossPda(program.programId);
		const bull = await findBullPda(program.programId, nftMint);
		const metaProgramId = new PublicKey(METAPLEX_PROGRAM_ID);
		const meta = await findMetaPda(nftMint);
		const nftAta = await getAssociatedTokenAddress(nftMint, userPub);
		const result = await program.methods
			.switchUri(choice)
			.accounts({
				bull: bull.pda,
				boss: boss.pda,
				nftAta: nftAta,
				tokenMetadataProgram: metaProgramId,
				metadata: meta.pda,
				nftMint,
				user: userPub,
			})
			.rpc();

		return result;
	} catch (error) {
		console.error('🚀 ~ file: solana.js ~ line 97 ~ switchUri ~ error', error);

		// TODO: Hardcoded error number
		// Error Code: NoCube. Error Number: 6003. Error Message: The bull has no tesseract!
		if (error.message.includes('6003')) {
			toast.error(`Error during interchanging: The bull has no tesseract`);
		} else {
			toast.error(`Error during interchanging: ${error.message}`);
		}

		throw error;
	}
};
