import { useCallback, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Pagination } from 'components/Pagination';
import { SectionHeading } from 'components/SectionHeading';
import { SectionDescription } from 'components/SectionDescription';

import { RoadmapCard } from './RoadmapCard';
import './index.scss';

const roadmapItems = [
	{
		type: 'Genesis',
		title: 'Mint',
		date: 'October 9th 2021',
		description: (
			<SectionDescription>
				10069 Bulls were minted in 17 minutes on October 9th, 2021. Due to the overwhelming demand, the Terra Network bottlenecked and transactions halted for several minutes.
				<br />
				<br />
				This was the beginning of The Bull Club journey.
			</SectionDescription>
		),
		poster: require('assets/img/home/roadmap/1.png'),
	},
	{
		type: 'The Rupture',
		title: 'Tesseracts + 3D Bulls',
		date: 'February 7th 2022',
		description: (
			<SectionDescription>
				We launched the world first interdimensional tokens. A digital collectible that, when bonded to a 2D Bull, it allows the owner to switch between its 2D and 3D form.
			</SectionDescription>
		),
		poster: require('assets/img/home/roadmap/2.png'),
	},
	{
		type: 'Migration',
		title: 'Migrate to Solana',
		date: 'Q4 2022',
		description: (
			<SectionDescription>
				Unfortunately, due to the renowned collapse of the Terra blockchain (now Terra Classic), we have decided that migrating to Solana with plans to
				go interchain at a later stage, was the best move for the future of the project.
			</SectionDescription>
		),
		poster: require('assets/img/home/roadmap/7.png'),
	},
	{
		type: 'Dao',
		title: 'The Bull Club',
		date: 'Q4 2022',
		description: (
			<SectionDescription>
				The most bullish club in crypto. The Bull Club is an exclusive club that can be accessed by owning a Bull Club NFT. The Bull Club includes a series of exciting perks and benefits from the growth of the brand.
			</SectionDescription>
		),
		poster: require('assets/img/home/roadmap/8.png'),
	},
	{
		type: 'Omnichain',
		title: 'Omnipresence',
		date: 'Q2 2023',
		description: (
			<SectionDescription>
				It's time to take ALL the portals. The Bull Club is becoming an omnichain collection. Transfer your Bulls and Tesseracts across a variety of blockchains.
			</SectionDescription>
		),
		poster: require('assets/img/home/roadmap/9.png'),
	},
	{
		type: 'Toxify',
		title: 'Toxic Tesseracts',
		date: 'SOON™',
		description: (
			<SectionDescription>
				The Toxic Tesseracts are coming and it's more exciting than ever. All Bull Club members (with interdimensional Bulls) will be able to mint them with the chance of pulling a Tier 1, 2, or 3 Toxic Tesseract.
				<br />
				<br />
			</SectionDescription>
		),
		poster: require('assets/img/home/roadmap/4.png'),
	},
	{
		type: 'Metaverse',
		title: 'Metaverse Characters',
		date: 'SOON™',
		description: (
			<SectionDescription>We are in the process of developing metaverse-ready characters for each of the Bulls in the collection. The goal is to implement these in multiple metaverse platforms across different chains.</SectionDescription>
		),
		poster: require('assets/img/home/roadmap/5.png'),
	},
	{
		type: 'Future',
		title: '[Redacted]',
		date: null,
		description: (
			<SectionDescription>
				The Bull Club Team are constantly building and coming up with new ideas, partnerships, games and innovative products to add value to the Bull Club. Keep your eyes peeled for the next best thing.
			</SectionDescription>
		),
		poster: require('assets/img/home/roadmap/6.png'),
	},
];

export const Roadmap = () => {
	const [activeIndex, setActiveIndex] = useState(0);
	const handleActiveIndexChange = useCallback(({ activeIndex }) => setActiveIndex(activeIndex), [setActiveIndex]);

	return (
		<section className="roadmap">
			<div className="container-wrapper">
				<SectionHeading>Roadmap</SectionHeading>
				<div className="roadmap-items">
					{roadmapItems.map((props, index) => (
						<RoadmapCard key={index} index={index} {...props} />
					))}
				</div>
				<Swiper className="roadmap-items-slider" slidesPerView={1} spaceBetween={20} onActiveIndexChange={handleActiveIndexChange}>
					{roadmapItems.map((props, index) => (
						<SwiperSlide key={index}>
							<RoadmapCard index={index} {...props} />
						</SwiperSlide>
					))}
				</Swiper>
				<Pagination activeIndex={activeIndex} itemsCount={roadmapItems.length} />
			</div>
		</section>
	);
};
