import classNames from 'classnames';
import {
	useCallback,
	// useLayoutEffect,
	useState,
} from 'react';

import './index.scss';

export const Tab = ({ defaultActiveKey, items, onTabChange }) => {
	const [activeTab, setActiveTab] = useState(defaultActiveKey);
	const handleTabButtonClick = useCallback(
		tabKey => {
			setActiveTab(tabKey);
			onTabChange?.(tabKey);
		},
		[onTabChange]
	);

	return (
		<div className="tab">
			<div className="tab-buttons">
				{items.map(({ title }, index) => (
					<button
						key={index}
						className={classNames('tab-button', { active: activeTab === title })}
						onClick={() => handleTabButtonClick(title)}
						// NOTE: At launch, we’ll only have the Rupture section working
						disabled={title !== 'Rupture'}
					>
						{title}
					</button>
				))}
			</div>
			<div
				className="container-wrapper"
				// className="container-wrapper extended"
			>
				{items.map(({ title, content }, index) => (
					<div key={index} className={classNames('tab-content', { active: activeTab === title })}>
						{content}
					</div>
				))}
			</div>
		</div>
	);
};
