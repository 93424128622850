import Tippy from '@tippyjs/react';
import { forwardRef } from 'react';

import 'tippy.js/dist/tippy.css';
import './index.scss';

const TOOLTIP_OFFSET = 20;

const arrow = `<div class="arrow"></div>`;
const popperOptions = {
	modifiers: [
		{
			name: 'flip',
			options: { fallbackPlacements: ['bottom'] },
		},
	],
};

export const Tooltip = forwardRef(({ visible, children, offset, content }, ref) => (
	<Tippy
		ref={ref}
		visible={visible}
		arrow={arrow}
		popperOptions={popperOptions}
		className="tooltip"
		placement="bottom"
		maxWidth={400}
		zIndex={100}
		content={content}
		offset={[0, offset ?? TOOLTIP_OFFSET]}
	>
		{children}
	</Tippy>
));
