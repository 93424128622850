import { Link } from 'components/Link';

import ToxxxifyBanner from 'assets/img/toxxxify-banner.svg';
import MainToxxxifyImage from 'assets/img/home/slider/2.jpg';
import ToxxxifyBanner2 from 'assets/img/tesseracts/toxxify.webp';

export const Toxxxify = () => (
	<div className="homepage-image-container-toxxxify">
		<img className="homepage-image-toxxxify" src={MainToxxxifyImage} alt="Slider 2" />
		<div className="svg-logo-container-toxxxify">
			<img src={ToxxxifyBanner} alt="toxxxifyLogo" className="svg-logo-toxxxify" />
		</div>
		<div className="svg-logo-container-toxxxify2">
			<img src={ToxxxifyBanner2} alt="toxxxifyLogo2" className="svg-logo-toxxxify2" />
		</div>

		<Link to={() => null} className="toxxxify-button button">
			Toxinomics (Soon)
		</Link>
	</div>
);
