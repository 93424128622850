import { Link } from 'components/Link';
import { Logo } from 'components/Logo';
import './index.scss';

const socialMediaLinks = [
	{ title: 'Telegram', link: 'https://t.me/thebullclub' },
	{ title: 'Twitter', link: 'https://twitter.com/TheBullClubNFT' },
	{ title: 'Discord', link: 'https://discord.com/invite/thebullclub' },
	{ title: 'Instagram', link: 'https://www.instagram.com/thebullclubnft/' },
];

export const Footer = () => (
	<footer className="footer">
		<div className="container-wrapper extended">
			<div className="footer-wrapper">
				<nav>
					<ul>
						{socialMediaLinks.map(({ title, link }, index) => (
							<li key={index}>
								<Link href={link} title={title} target="_blank" rel="noopener noreferrer">
									{title}
								</Link>
							</li>
						))}
					</ul>
				</nav>
				<Logo />
				{/* <small className="copyright">Copyright © Bull club {new Date().getFullYear()}</small> */}
			</div>
		</div>
	</footer>
);
