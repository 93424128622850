import { MarketplaceCard } from 'components/MarketplaceCard';

import { partners } from 'constants';
import './index.scss';

export const SecondaryMarketplaces = () => (
	<section className="partnered-with">
		<div className="container-wrapper">
			<div className="partnered-with-wrapper">
				<h1>Secondary Marketplaces</h1>
				<ul className="partners-list">
					{partners.map(({ title, logo, url }, index) => (
						<MarketplaceCard key={index} title={`Buy on ${title}`} url={url} logo={logo} />
					))}
				</ul>
			</div>
		</div>
	</section>
);
