import { Link } from 'components/Link';

import BullClubLogo from 'assets/img/the-bull-club/logoV2.svg';
import MainImage from 'assets/img/home/slider/clubV2.png';
import XImage from 'assets/img/home/slider/XLogo.svg';

export const ClubV2 = () => (
	<div className="homepage-image-container-v2">
		<img className="homepage-image-v2" src={MainImage} alt="Slider 1" />
		<div className="svg-logo-container-v2">
			<img src={BullClubLogo} alt="bullClubLogo" className="svg-logo-v2" />
		</div>
		<Link className="v2-button button">
			<span>MINTING SOON</span>
		</Link>	
		<div className="svg-text-container-v2">
			<p className="v2-font2">FOLLOW US ON X FOR UPDATES</p>
		</div>
		<a href='https://x.com/TheBullClubNFT' target='_blank' rel='noopener noreferrer'>
			<img className="homepage-image-xlogo" src={XImage} alt="Logos" />
		</a>
	</div>
);