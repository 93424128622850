import LayerZeroLogo from 'assets/img/partners-logos/layerzero.svg';
import OmniXLogo from 'assets/img/partners-logos/omnix.svg';
import AngelAllianceLogo from 'assets/img/partners-logos/angel-alliance.webp';
import './index.scss';

const protocolLogos = [
	{ src: LayerZeroLogo, alt: 'LayerZero Logo' },
	{ src: OmniXLogo, alt: 'Omni-X Logo' },
	{ src: AngelAllianceLogo, alt: 'Angel Alliance Logo' },
];

export const ProtocolLogos = () => (
	<div className="protocol-logos">
		<div className="container-wrapper extended">
			<div className="protocol-logos-wrapper">
				{protocolLogos.map(({ src, alt }, index) => (
					<img key={index} src={src} alt={alt} />
				))}
			</div>
		</div>
	</div>
);
