import axios from 'axios';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { WalletProvider as TerraWalletProvider, getChainOptions } from '@terra-money/wallet-provider';

import { App } from 'App';

import 'swiper/css/bundle';
import 'swiper/css/navigation';
import '@solana/wallet-adapter-react-ui/styles.css';

import './scss/index.scss';

axios.defaults.baseURL = 'https://launchpad.webisoft.org/api';

const root = ReactDOM.createRoot(document.getElementById('root'));

getChainOptions().then(chainOptions => {
	root.render(
		<StrictMode>
			<TerraWalletProvider {...chainOptions}>
				<App />
			</TerraWalletProvider>
		</StrictMode>
	);
});
