import { Link } from 'components/Link';
import { StrokedText } from 'components/StrokedText';
import { SectionHeading } from 'components/SectionHeading';
import { SectionDescription } from 'components/SectionDescription';

import angelProtocol from 'assets/img/partners-logos/angel-protocol-white.png';
import './index.scss';

export const AngelProtocol = () => (
	<section className="angel-protocol">
		<div className="container-wrapper extended">
			<div className="angel-protocol-wrapper">
				<div className="donated-amount">
					<small>USD</small>
					<StrokedText text="$300,000+" />
					<time>Nov 2022</time>
				</div>
				<div className="donation-link">
					<SectionHeading>
						Donated To
						<br />
						Charity
					</SectionHeading>
					<SectionDescription>Through royalties and charity auctions.</SectionDescription>
					<Link href="https://better.giving/" target="_blank" rel="noopener noreferrer" title="Visit Angel" variant="button">
						Visit Angel
					</Link>
				</div>
				<img className="angel-protocol-logo" src={angelProtocol} alt="Angel Protocol" />
			</div>
		</div>
	</section>
);
