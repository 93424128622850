import ME from 'assets/img/partners-logos/magic-eden.svg';
import openSea from 'assets/img/partners-logos/opensea.svg';
import tensor from 'assets/img/partners-logos/tensor.svg'
//import hyperspace from 'assets/img/partners-logos/hyperspace.svg';

export const partners = [
	{
		title: 'Tensor',
		logo: tensor,
		url: 'https://www.tensor.trade/trade/the_bull_club',
		token_url: null,
	},
	{
		title: 'Magic Eden',
		logo: ME,
		url: 'https://magiceden.io/marketplace/the_bull_club',
		token_url: null,
	},
	{
		title: 'OpenSea',
		logo: openSea,
		url: 'https://opensea.io/collection/the-bull-club-og',
		token_url: null,
	},
	// {
	// 	title: 'Hyperspace',
	// 	logo: hyperspace,
	// 	url: 'https://hyperspace.xyz/collection/DAbt3ZSyBbKbrUDfbdv7XMJQTAGL8iMUdM6DDEJkSE5c',
	// },
];

export const mockBullImages = [
	10000, 10001, 10002, 10003, 10005, 10017, 10033, 10041, 10048, 10054, 10056, 10058, 10059, 10060, 10062, 10063, 10065, 10066, 10068, 10069,
];

export const cubes = [
	'tesseract',
	// 'toxic', 'toxxic', 'toxxxic', 'toxxify'
];
