import { Link } from 'components/Link';
import { SectionHeading } from 'components/SectionHeading';
import { SectionDescription } from 'components/SectionDescription';
import ComingSoon from 'assets/img/home/slider/omniportals.png';
// import ReactPlayer from 'react-player';

import video from 'assets/Omnichain.mp4';

import './index.scss';

export const BullClub = () => (
	<section className="bull-club">
		<div className="container-wrapper">
			<div className="bull-club-wrapper">
				<div className="bull-club-content">
					{/* <strong>Coming Soon...</strong> */}
					<SectionHeading>The Next Chapter</SectionHeading>
					<SectionDescription>
						The Bull Club is going omnichain. Transfer your assets between a wide range of blockchains. You choose when and how often. Take advantage of liquidity rotations in the market and more.
						<br />
						<br />
						Read about the transition in the blog post below.
					</SectionDescription>
					<div className="read-blog-button">
						<Link
							href="https://medium.com/@TheBullClub/the-bull-club-goes-omnichain-ebd291056d5d"
							target="_blank"
							title="The Bull Club"
							variant="button"
							style={{ padding: '10px 35px', width: '250px' }}
						>
							Read the article here
						</Link>
					</div>
				</div>
				<div className="bull-club-poster">
					<video width="100%" height="100%" controls poster={ComingSoon}>
						<track kind="captions" srcLang="en" label="Omnichain" />
						<source src={video} type="video/mp4" />
					</video>
				</div>
			</div>
		</div>
	</section>
);
