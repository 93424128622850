import { useContext } from 'react';

import { Link } from 'components/Link';
//import { Button } from 'components/Button';
import { SectionHeading } from 'components/SectionHeading';
//import { SectionDescription } from 'components/SectionDescription';

import { partners } from 'constants';
import { NFTsContext } from 'contexts/NFTs';

import './index.scss';

export const Messages = ({ connecting, isConnected, onConnectButtonClick }) => {
	// eslint-disable-next-line no-unused-vars
	const { isLoading, hasLunabull } = useContext(NFTsContext);

	return (
		<section className="messages">
			<div className="container-wrapper">
				{!isConnected && (
					<><em>Connect your wallet <br />to enter the Club!</em>
						{/* <em>Want to enter the Club?</em>
						<SectionHeading>Connect your wallet</SectionHeading>
						<Button variant="primary" onClick={onConnectButtonClick}>
							{connecting ? 'Connecting Wallet...' : 'Connect Wallet'}
						</Button> */  }
					</>
				)}
				{isConnected && !hasLunabull && (
					<>
						<em>No Bulls found in the wallet.</em>
						<SectionHeading>Buy a Bull to enter the Club!</SectionHeading>
						<div className="marketplace-links">
							{partners.map(({ title, url }) => (
								<Link key={title} size="medium" href={url} title={title} target="_blank" rel="noopener noreferrer" variant="button">
									{title}
								</Link>
							))}
						</div>
					</>
				)}
			</div>
		</section>
	);
};
