import classNames from 'classnames';
import { forwardRef } from 'react';

import './index.scss';

export const Button = forwardRef(
	({ className, variant = 'primary', size, children, isLoading, isFullWidth = false, isReadonly = false, ...otherProps }, ref) => (
		<button
			// Format
			ref={ref}
			className={classNames('button', className)}
			data-variant={variant}
			data-size={size}
			data-full-width={isFullWidth}
			data-readonly={isReadonly}
			{...otherProps}
		>
			{isLoading ? (
				<svg width={30} height={30} viewBox="-5 -5 48 48" stroke="currentColor">
					<g transform="translate(1 1)" strokeWidth={4} fill="none" fillRule="evenodd">
						<circle strokeOpacity={0.5} cx={18} cy={18} r={18} />
						<path d="M36 18c0-9.94-8.06-18-18-18">
							<animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
						</path>
					</g>
				</svg>
			) : (
				children
			)}
		</button>
	)
);
