import './index.scss';

export const CoreTeamMemberCard = ({ name, image, position, address }) => (
	<div className="core-team-members-item">
		<img src={image} alt={name} />
		<strong>{name}</strong>
		<em>{position}</em>
		<a href={`https://twitter.com/${address}`} title={`Link to twitter account of ${name}`} target="_blank" rel="noopener noreferrer">
			<span>Twitter:&nbsp;</span>@{address}
		</a>
	</div>
);
