import { Routes as AppRoutes, Route } from 'react-router-dom';

import Home from 'views/home';
// import Collection from 'views/collection';
// import RecentSales from 'views/recent-sales';
import TheBullClub from 'views/the-bull-club';
import ComingSoon from 'views/coming-soon';
import NotFound from 'views/not-found';
import TheBullPortal from 'views/the-bull-portal';

export const Routes = () => {
	return (
		<AppRoutes>
			<Route exact path="/" element={<Home />} />
			{/* <Route path="/collection" element={<Collection />} />
			<Route path="/activity" element={<RecentSales />} /> */}
			<Route path="/the-bull-club" element={<TheBullClub />} />
			<Route path="/the-bull-portal" element={<TheBullPortal />} />
			<Route path="/coming-soon" element={<ComingSoon />} />
			<Route path="*" element={<NotFound />} />
		</AppRoutes>
	);
};
