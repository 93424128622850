import { useCallback } from 'react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';

import { Base } from 'Base';
import { Link } from 'components/Link';
import { Button } from 'components/Button';

import MostBullishImage from 'assets/img/home/slider/poker.png';
import './index.scss';

const ComingSoon = () => {
	const { setVisible } = useWalletModal();
	const { connecting } = useSolanaWallet();
	const handleSelectWalletButtonClick = useCallback(() => setVisible(true), [setVisible]);

	return (
		<Base title="Coming Soon">
			<div className="coming-soon-container">
				<img src={MostBullishImage} alt="image404" className="image-404" />
				<div className="coming-soon-inner-div">
					<p className="coming-soon-font">This page is</p>
					<p className="coming-soon-font">coming soon</p>
					<Link to="/" variant="button" style={{ padding: '15px 50px', fontSize: '17px' }}>
						Go to homepage
					</Link>
					<span style={{ display: 'inline-block', fontSize: '17px', margin: '15px 0' }}>or</span>
					<Button
						variant="primary"
						size="medium"
						style={{ padding: '22px 50px', fontSize: '17px' }}
						className="select-wallet-button"
						onClick={handleSelectWalletButtonClick}
					>
						{connecting ? 'Connecting Wallet...' : 'Connect Wallet'}
					</Button>
				</div>
			</div>
		</Base>
	);
};

export default ComingSoon;
