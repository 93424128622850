import NiceModal from '@ebay/nice-modal-react';

import { Modal } from 'components/Modal';
import { SectionHeading } from 'components/SectionHeading';

import './index.scss';

NiceModal.register('rupture-modal', Modal);

export const RuptureModal = ({ activeTab, previousNft, newNft }) => {
	return (
		<>
			<div className="modal-body desktop">
				<div className="modal-left">
					<span className="modal-label">The Rupture</span>
					<SectionHeading>
						{`${activeTab === 'bond' ? 'Upgraded' : 'Interchanged'}`}
						<br />
						Bull
					</SectionHeading>
					<div className="upgrade-bull-info">
						<p>You {`${activeTab === 'bond' ? 'bonded' : 'interchanged'}`}:</p>
						<h2>
							{previousNft?.choice === 0 ? '2D' : '3D'} {previousNft?.name}
						</h2>
						<em>Custom</em>
					</div>
					{activeTab === 'interchange' && (
						<div className="upgrade-bull-info">
							<p>You received:</p>
							<h2>
								{newNft?.choice === 0 ? '2D' : '3D'} {newNft?.name}
							</h2>
							<em>Custom</em>
						</div>
					)}
					{/* <Button isFullWidth>View Lunbull</Button> */}
				</div>
				<div className="modal-right">
					<div className="modal-image-wrapper">
						<img src={newNft?.image} alt={newNft?.name} />
					</div>
				</div>
			</div>
			<div className="modal-body mobile">
				<span className="modal-label">The Rupture</span>
				<SectionHeading>
					Upgrade
					<br />
					Your Bull
				</SectionHeading>
				<img className="modal-main-image" src={newNft?.image} alt={newNft?.name} />
				<div className="upgrade-bull-info">
					<img src={previousNft?.image} alt={previousNft?.name} />
					<div className="upgrade-bull-info-content">
						<p>You {`${activeTab === 'bond' ? 'bonded' : 'interchanged'}`} tesseract to:</p>
						<h2>
							{previousNft?.choice === 0 ? '2D' : '3D'} {previousNft?.name}
						</h2>
						<em>Custom</em>
					</div>
				</div>
				{activeTab === 'interchange' && (
					<div className="upgrade-bull-info">
						<img src={newNft?.image} alt={newNft?.name} />
						<div className="upgrade-bull-info-content">
							<p>You received:</p>
							<h2>
								{newNft?.choice === 0 ? '2D' : '3D'} {newNft?.name}
							</h2>
							<em>Custom</em>
						</div>
					</div>
				)}

				{/* <Button isFullWidth>View Lunbull</Button> */}
				<br />
			</div>
		</>
	);
};

export const showRuptureModal = ({ activeTab, previousNft, newNft }) =>
	NiceModal.show('rupture-modal', {
		className: 'rupture-modal',
		children: <RuptureModal activeTab={activeTab} previousNft={previousNft} newNft={newNft} />,
	});
