import { Toaster } from 'react-hot-toast';
import { Suspense, useMemo } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
    WalletModalProvider,
} from '@solana/wallet-adapter-react-ui';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import {
	TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';

import { Routes } from 'routes';
import { endpoint } from 'api/constants';
import { NFTsContextProvider } from 'contexts/NFTs';

const queryClient = new QueryClient();

export const App = () => {
	const wallets = useMemo(
		() => [
			// @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
			// Only the wallets you configure here will be compiled into your application, and only the dependencies
			// of wallets that your users connect to will be loaded.
			new TorusWalletAdapter(),
		],
		[]
	);

	return (
		<ConnectionProvider endpoint={endpoint}>
			<WalletProvider wallets={wallets}>
				<WalletModalProvider>
					<BrowserRouter>
						<HelmetProvider>
							<QueryClientProvider client={queryClient}>
								<Suspense fallback="loading">
									<NFTsContextProvider>
										<NiceModal.Provider>
											<Routes />
											<Toaster duration={5} position="top-right" />
										</NiceModal.Provider>
									</NFTsContextProvider>
								</Suspense>
							</QueryClientProvider>
						</HelmetProvider>
					</BrowserRouter>
				</WalletModalProvider>
			</WalletProvider>
		</ConnectionProvider>
	);
};
