import { Link } from 'components/Link';

import PortalsImage from 'assets/img/home/slider/omniportals.png';
import PortalsImageMobile from 'assets/img/home/slider/omniportals-mobile.png';
import LogosImage from 'assets/img/home/slider/omnilogos.png';

export const Omnichain = () => (
	<div className="homepage-image-container-omnichain">
		<img className="homepage-image-omnichain" src={PortalsImage} alt="Slider 1" />
		<img className="homepage-image-omnichain-mobile" src={PortalsImageMobile} alt="Slider 2" />
		<div className="svg-logo-container-omnichain">
			<p className="omnichain-font">OUR</p>
			<p className="omnichain-font2">OMNICHAIN</p>
			<p className="omnichain-font3">FUTURE</p>
		</div>
		<Link 
			href={'https://medium.com/@TheBullClub/the-bull-club-goes-omnichain-ebd291056d5d'}
			target="_blank"
			rel="noopener noreferrer" className="omnichain-button button">
			<span>READ THE BLOG POST</span>
		</Link>		
		<img className="homepage-image-omnilogos" src={LogosImage} alt="Logos" />

	</div>
);