import { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import shuffle from 'lodash/shuffle';
import './index.scss';

const scrollable2DBulls = [
	{
		image: require('assets/img/bulls/2D/new/2118.png'),
		id: '2118',
	},
	{
		image: require('assets/img/bulls/2D/new/3851.png'),
		id: '3851',
	},
	{
		image: require('assets/img/bulls/2D/new/5103.png'),
		id: '5103',
	},
	{
		image: require('assets/img/bulls/2D/new/5201.png'),
		id: '5201',
	},
	{
		image: require('assets/img/bulls/2D/new/5233.png'),
		id: '5233',
	},
	{
		image: require('assets/img/bulls/2D/new/5381.png'),
		id: '5381',
	},
	{
		image: require('assets/img/bulls/2D/new/5392.png'),
		id: '5392',
	},
	{
		image: require('assets/img/bulls/2D/new/5411.png'),
		id: '5411',
	},
	{
		image: require('assets/img/bulls/2D/new/5428.png'),
		id: '5428',
	},
	{
		image: require('assets/img/bulls/2D/new/5473.png'),
		id: '5473',
	},
	{
		image: require('assets/img/bulls/2D/new/5485.png'),
		id: '5485',
	},
	{
		image: require('assets/img/bulls/2D/new/5508.png'),
		id: '5508',
	},
	{
		image: require('assets/img/bulls/2D/new/5509.png'),
		id: '5509',
	},
	{
		image: require('assets/img/bulls/2D/new/6497.png'),
		id: '6497',
	},
	{
		image: require('assets/img/bulls/2D/new/6514.png'),
		id: '6514',
	},
	{
		image: require('assets/img/bulls/2D/new/6568.png'),
		id: '6568',
	},
	{
		image: require('assets/img/bulls/2D/new/6572.png'),
		id: '6572',
	},
	{
		image: require('assets/img/bulls/2D/new/6643.png'),
		id: '6643',
	},
	{
		image: require('assets/img/bulls/2D/new/6670.png'),
		id: '6670',
	},
	{
		image: require('assets/img/bulls/2D/new/6672.png'),
		id: '6672',
	},
];

const scrollable3DBulls = [
	{
		image: require('assets/img/bulls/3D/new//2118.jpg'),
		id: '2118',
	},
	{
		image: require('assets/img/bulls/3D/new//3851.jpg'),
		id: '3851',
	},
	{
		image: require('assets/img/bulls/3D/new//5103.jpg'),
		id: '5103',
	},
	{
		image: require('assets/img/bulls/3D/new//5201.jpg'),
		id: '5201',
	},
	{
		image: require('assets/img/bulls/3D/new//5233.jpg'),
		id: '5233',
	},
	{
		image: require('assets/img/bulls/3D/new//5381.jpg'),
		id: '5381',
	},
	{
		image: require('assets/img/bulls/3D/new//5392.jpg'),
		id: '5392',
	},
	{
		image: require('assets/img/bulls/3D/new//5411.jpg'),
		id: '5411',
	},
	{
		image: require('assets/img/bulls/3D/new//5428.jpg'),
		id: '5428',
	},
	{
		image: require('assets/img/bulls/3D/new//5473.jpg'),
		id: '5473',
	},
	{
		image: require('assets/img/bulls/3D/new//5485.jpg'),
		id: '5485',
	},
	{
		image: require('assets/img/bulls/3D/new//5508.jpg'),
		id: '5508',
	},
	{
		image: require('assets/img/bulls/3D/new//5509.jpg'),
		id: '5509',
	},
	{
		image: require('assets/img/bulls/3D/new//6497.jpg'),
		id: '6497',
	},
	{
		image: require('assets/img/bulls/3D/new//6514.jpg'),
		id: '6514',
	},
	{
		image: require('assets/img/bulls/3D/new//6568.jpg'),
		id: '6568',
	},
	{
		image: require('assets/img/bulls/3D/new//6572.jpg'),
		id: '6572',
	},
	{
		image: require('assets/img/bulls/3D/new//6643.jpg'),
		id: '6643',
	},
	{
		image: require('assets/img/bulls/3D/new//6670.jpg'),
		id: '6670',
	},
	{
		image: require('assets/img/bulls/3D/new//6672.jpg'),
		id: '6672',
	},
];

let new2DBullsArray = shuffle(scrollable2DBulls);
let new3DBullsArray = shuffle(scrollable3DBulls);

const scrollTriggerOptions = {
	scrub: 1,
	start: '-50% bottom',
	end: '100% top',
};

const items = new Array(4).fill(null);
gsap.registerPlugin(ScrollTrigger);

export const ScrollableBulls = () => {
	useEffect(() => {
		gsap.to('.left', { x: '+=-40%', scrollTrigger: scrollTriggerOptions });
		gsap.to('.right', { x: '+=40%', scrollTrigger: scrollTriggerOptions });

		ScrollTrigger.refresh();
	}, []);

	const halfScrollable2DBulls = Math.ceil(new2DBullsArray.length / 2) || [];
	const halfScrollable3DBulls = Math.ceil(new3DBullsArray.length / 2) || [];

	return (
		<section className="scrollable-bulls">
			<div className="image-wrapper left">
				{[...items.keys()].map(() => new2DBullsArray.slice(0, halfScrollable2DBulls).map(({ image, id }) => <img src={image} key={id} alt={`Bull ${id}`} loading="lazy" />))}
			</div>
			<div className="image-wrapper right">
				{[...items.keys()].map(() => new3DBullsArray.slice(0, halfScrollable3DBulls).map(({ image, id }) => <img src={image} key={id} alt={`Bull ${id}`} loading="lazy" />))}
			</div>
			<div className="image-wrapper left">
				{[...items.keys()].map(() => new2DBullsArray.slice(-halfScrollable2DBulls).map(({ image, id }) => <img src={image} key={id} alt={`Bull ${id}`} loading="lazy" />))}
			</div>
			<div className="image-wrapper right">
				{[...items.keys()].map(() => new3DBullsArray.slice(-halfScrollable3DBulls).map(({ image, id }) => <img src={image} key={id} alt={`Bull ${id}`} loading="lazy" />))}
			</div>
		</section>
	);
};
