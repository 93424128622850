import { useCallback, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Pagination } from 'components/Pagination';
import { SectionHeading } from 'components/SectionHeading';
import { CoreTeamMemberCard } from './CoreTeamMemberCard';

import './index.scss';

const coreTeamMembers = [
	{
		image: require('assets/img/team-members/15.jpg'),
		name: 'Fizzy',
		position: 'Founder',
		address: 'FizzyTBC',
	},
	{
		image: require('assets/img/team-members/13.webp'),
		name: 'TZM',
		position: 'Operations',
		address: 'tzmcrypto',
	},
	{
		image: require('assets/img/team-members/14.jpg'),
		name: 'Jordy',
		position: 'Designer',
		address: 'jhoweybeattie',
	},
];

export const CoreTeam = () => {
	const [activeIndex, setActiveIndex] = useState(0);
	const handleActiveIndexChange = useCallback(({ activeIndex }) => setActiveIndex(activeIndex), [setActiveIndex]);

	return (
		<section className="core-team">
			<div className="container-wrapper">
				<div className="core-team-container">
					<header className="section-header">
						<SectionHeading>Core Team</SectionHeading>
						<Pagination activeIndex={activeIndex} itemsCount={coreTeamMembers.length} />
					</header>
				</div>

				<div className="core-team-members">
					{coreTeamMembers.map((props, index) => (
						<CoreTeamMemberCard key={index} {...props} />
					))}
				</div>
			</div>
			<Swiper className="members-slider" slidesPerView={1} spaceBetween={20} width={218} onActiveIndexChange={handleActiveIndexChange}>
				{coreTeamMembers.map((props, index) => (
					<SwiperSlide key={index}>
						<CoreTeamMemberCard {...props} />
					</SwiperSlide>
				))}
			</Swiper>
		</section>
	);
};
