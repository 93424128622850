import { Keyboard, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useCallback, useState, useRef } from 'react';
import { Pagination } from 'components/Pagination';

import { Omnichain } from './SlideComponents/Omnichain'
import { Toxxxify } from './SlideComponents/Toxxxify';
import { ClubV2 } from './SlideComponents/ClubV2';
import { MostBullish } from './SlideComponents/MostBullish';
// import { Portal } from './SlideComponents/Portal';
// import { TheBullClub } from './SlideComponents/TheBullClub';
// import { Coffee } from './SlideComponents/Coffee'

import './index.scss';

const slides = [<ClubV2 />, <Omnichain />, <Toxxxify />, <MostBullish />];

export const Slider = () => {
	const swiperRef = useRef(null);
	const [activeIndex, setActiveIndex] = useState(0);
	const handlePrevButtonClick = useCallback(() => swiperRef.current.swiper.slidePrev(), []);
	const handleNextButtonClick = useCallback(() => swiperRef.current.swiper.slideNext(), []);
	const handleActiveIndexChange = useCallback(
		({ activeIndex }) => {
			// NOTE: Because of the looped slider we have custom logic here.
			if (activeIndex === 0) {
				setActiveIndex(slides.length - 1);
			} else {
				setActiveIndex(activeIndex > slides.length ? 0 : activeIndex - 1);
			}
		},
		[setActiveIndex]
	);

	return (
		<section className="main-slider">
			<div className="container-wrapper extended">
				<button className="control-button prev-button" onClick={handlePrevButtonClick} />
				<button className="control-button next-button" onClick={handleNextButtonClick} />
				<Pagination activeIndex={activeIndex} itemsCount={slides.length} />
			</div>
			<Swiper ref={swiperRef} loop keyboard={{ enabled: true }} autoplay={{delay: 4500, disableOnInteraction: false, }} onActiveIndexChange={handleActiveIndexChange} modules={[Autoplay, Keyboard]}>
				{slides.map((slide, index) => {
					return (
						<SwiperSlide key={index} data-index={index}>
							{slide}
						</SwiperSlide>
					);
				})}
			</Swiper>
		</section>
	);
};
